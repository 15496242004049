import React from 'react';
import { TableColumn } from 'react-data-table-component';
import ProviderPanel, { RMDFlag } from '../../components/ProviderPanel';
import { ExtraNoteType, Notes } from '../../components/shared/Notes';
import SignerHopCellContent from '../../components/stirShaken/SignerHopCellContent';
import { Hop, NoteType } from '../../interfaces/hop';
import { getClientFormattedDate } from '../utilities';
import { HopElapsedColumn } from './hopsColumns';

const upstreamProviderSort = (a: any, b: any) => {
  if (a.upstreamProvider && b.upstreamProvider) {
    if (a.upstreamProvider.name > b.upstreamProvider.name) {
      return 1;
    }

    if (a.upstreamProvider.name < b.upstreamProvider.name) {
      return -1;
    }
  }

  if (a.upstreamProvider) {
    return 1;
  }

  if (b.upstreamProvider) {
    return -1;
  }

  return 0;
};
const downstreamProviderSort = (a: any, b: any) => {
  if (a && b && a.downstreamProvider && b.downstreamProvider) {
    if (a.downstreamProvider.name > b.downstreamProvider.name) {
      return 1;
    }

    if (a.downstreamProvider.name < b.downstreamProvider.name) {
      return -1;
    }
  }

  if (a.downstreamProvider) {
    return 1;
  }

  if (b.downstreamProvider) {
    return -1;
  }

  return 0;
};
export const expandedRowColumns = (includeLinks: boolean): TableColumn<Hop>[] => [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Campaign',
    selector: () => 'campaignName',
    sortable: true,
    grow: 3,
    cell: (hop: Hop) =>
      includeLinks ? (
        <div
          className="table-cell blue"
          onClick={() => (window.location.href = `/hops/hop/${hop.hopId}`)}
        >
          <a href={`/campaigns/campaign/${hop.campaignId}`}>{hop.campaignName}</a>
        </div>
      ) : (
        <div className="table-cell">{hop.campaignName}</div>
      )
  },
  {
    name: 'Traceback',
    selector: () => 'tracebackId',
    sortable: true,
    cell: (hop: Hop) =>
      includeLinks ? (
        <div
          className="table-cell blue center"
          onClick={() => (window.location.href = `/hops/hop/${hop.hopId}`)}
        >
          <a href={`/tracebacks/traceback/${hop.tracebackId}`}>{hop.tracebackId}</a>
        </div>
      ) : (
        <div className="table-cell">{hop.tracebackId}</div>
      )
  },
  {
    name: 'Notes',
    selector: () => 'notes',
    sortable: false,
    grow: 2,
    cell: (hop: Hop) => (
      <div className="table-cell center wrap-text">
        <a href={`/hops/hop/${hop.hopId}`}>
          <Notes
            types={hop.notes}
            customToolTipFunc={(type: NoteType | ExtraNoteType) =>
              type === NoteType.DownNoResponseHop && hop.actionTaken
                ? hop.actionTaken + '\n' + hop.explanation
                : ''
            }
          />
          {hop.rmdFlag && <RMDFlag rmdFlag={hop.rmdFlag} />}
        </a>
      </div>
    )
  },
  {
    name: 'Notified Date',
    selector: () => 'notified',
    sortable: true,
    grow: 3,
    cell: (hop: Hop) => (
      <div className="table-cell center">{getClientFormattedDate(hop.notified)}</div>
    )
  },
  {
    name: 'Received Call From',
    selector: () => 'upstreamProviderName',
    sortable: true,
    sortFunction: upstreamProviderSort,
    grow: 3,
    cell: (hop: Hop) => (
      <ProviderPanel
        hyperlink={includeLinks}
        includeReputation
        provider={hop.upstreamProvider}
        rmdFlag={hop.upstreamRMDFlag}
      />
    )
  },
  {
    name: 'Sent Call To',
    selector: () => 'downstreamProviderName',
    sortable: true,
    sortFunction: downstreamProviderSort,
    grow: 3,
    cell: (hop: Hop) => (
      <ProviderPanel
        hyperlink={includeLinks}
        includeReputation
        provider={hop.downstreamProvider}
        rmdFlag={hop.downstreamRMDFlag}
      />
    )
  },
  {
    name: 'Signer',
    selector: () => 'signerProviderName',
    sortable: false,
    grow: 2,
    cell: (hop: Hop) => <SignerHopCellContent hop={hop} includeLinks={includeLinks} />
  },
  {
    name: 'Attestation',
    selector: () => 'attest',
    sortable: false,
    grow: 1,
    cell: (hop: Hop) => (
      <div className="table-cell center">
        {hop.stirShaken && <span>{hop.stirShaken.attest}</span>}
      </div>
    )
  },
  {
    name: 'Response Time',
    selector: () => 'elapsed',
    sortable: true,
    grow: 2,
    cell: (hop: Hop) => <HopElapsedColumn hop={hop} />
  }
];
